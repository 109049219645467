import React from 'react';
import { styled, Box, List, ListItem } from '@mui/material';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { NavItem } from 'shared/types';

interface Props {
  navItems: ReadonlyArray<{
    slug: string;
    internalName: string;
  } | null> | null;
}

const NavigationItems = ({ navItems }: Props) => {
  const location = useLocation();
  const menuItems = navItems?.filter(
    (navItem): navItem is NavItem => !!navItem?.slug,
  );

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <NavList>
        {menuItems &&
          menuItems.map((menuItem) => {
            return (
              <NavListItem key={menuItem.slug}>
                <NavLink
                  to={`/${menuItem.slug}`}
                  isActive={
                    menuItem.slug
                      ? location.pathname.includes(menuItem.slug)
                      : false
                  }
                  partiallyActive={true}
                >
                  {menuItem.internalName}
                </NavLink>
              </NavListItem>
            );
          })}
      </NavList>
    </Box>
  );
};

export default NavigationItems;

const NavList = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '100px 0 0',

  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
  },
}));

const NavListItem = styled(ListItem)(({ theme }) => ({
  width: 'fit-content',
  padding: '8px 0 0 0',

  [theme.breakpoints.up('lg')]: {
    ...theme.typography.button2,
    padding: '0 42px 0 0',

    '&:last-of-type': {
      padding: 0,
    },
  },
}));

const NavLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  color: theme.palette.primary.offBlack,
  textDecoration: 'none',
  fontSize: isActive ? '2.5rem' : '1.5rem',
  fontWeight: isActive ? 700 : 500,
  padding: 0,

  [theme.breakpoints.up('lg')]: {
    fontSize: isActive ? '1.5rem' : '1rem',
    // fontSize: theme.typography.button2.fontSize,
    color: theme.palette.primary.main,

    '&:hover': {
      color: theme.palette.primary.yellow,
      fontSize: '1.5rem',
    },
  },
}));
